import {Box, Grid, Typography} from "@mui/material";
import News from "./News";
import news1 from '../assets/images/news/first.jpg';
import news2 from '../assets/images/news/Congratulations.gif';
import news3 from '../assets/images/news/fifa-world-cup-22.jpg';
function NewsBar(){
    const news = [
        {
            title:"Inaugural AGM of Alumni Association of Uva Wellassa University",
            time:"1 Sep 2023 | 8:35PM",
            image:news1,
            des:"The Inaugural annual general meeting of the Alumni Association of Uva Wellassa University was held on the 15th of October 2023 at the Senate of the Uva Wellassa University. \n" +
                "\n" +
                "The felicitation for the Vice Chancellor Prof. J.L. Rathnasekara was a key highlight of the event.\n" +
                "\n" +
                "\n",
            link:"#"
        },{
            title:"Celebrating Professor D.K.D.D. Jayasena’s Remarkable Achievement",
            time:"1 Sep 2023 | 8:35PM",
            image:news2,
            des:"Prof. D.K.D.D. Jayasena has been named among the world’s top 2% scientists, a prestigious recognition published by Elsevier. This award is a testament to his remarkable contributions in the field of Animal Science. Professor Jayasena is currently affiliated with the Department of Animal Science in the Faculty of Animal Science and Export Agriculture.\n" +
                "\n" +
                "Among a select group of 42 distinguished researchers from Sri Lanka featured in this elite 2% category, Professor Jayasena’s inclusion is a source of immense pride for the Uva Wellassa University and the entire scientific community of Sri Lanka.\n" +
                "\n" +
                "Link this URL with more icon ",
            link:"https://www.uwu.ac.lk/professor-d-k-d-d-jayasena-has-been-recognized-as-one-of-the-top-2-scientists-globally-according-to-the-elsevierstanford-list/"
        },
        {
            title:"Department of Animal Science, UWU represents FIFA World Cup 2022",
            time:"1 Sep 2023 | 8:35PM",
            image:news3,
            des:"Dr.Kaushalya Wijayasekara, a Senior Lecturer attached to the Department of Animal Science, Uva Wellassa University was privileged to be a part of the Food Safety team of FIFA World Cup 2022 which was held in Qatar. The Football World Cup is the most prestigious association football tournament in the world. This event is considered as the most widely viewed and followed single sporting event in the world"
            ,link: "https://www.uwu.ac.lk/department-of-animal-science-uwu-represents-fifa-world-cup-2022/"
        }
    ]

    return(
        <Box sx={{
            width:'100%',
            justifyContent:'center',
            display:'flex',
            marginTop:'50px',
            marginBottom:'100px'
        }}>

            <Box sx={{
                width:'85%'
            }}>
                <Typography align={'left'} width={'100%'} variant={'h3'} fontWeight={'600'}
                sx={{
                    color:'#091D3D'
                }}>Recent News
                </Typography>

                <Grid container spacing={3} sx={{
                    marginTop:'50px',
                }}>
                    <Grid item md={4}>
                        <News link={news[0].link} time={news[0].time} image={news[0].image} des={news[0].des} title={news[0].title}/>
                    </Grid>
                    <Grid item md={4}>
                        <News link={news[1].link} time={news[1].time} image={news[1].image} des={news[1].des} title={news[1].title}/>
                    </Grid>
                    <Grid item md={4}>
                        <News link={news[2].link} time={news[2].time} image={news[2].image} des={news[2].des} title={news[2].title}/>
                    </Grid>
                </Grid>

            </Box>

        </Box>
    )
}
export default NewsBar