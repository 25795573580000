import {Box, Button, Typography} from "@mui/material";
import {theme} from "../stylesheet";
interface props{
    image:string;
    title:string;
    time:string;
    des:string;
    link:string
}

function News(prop:props){
    return(
        <Box sx={{width:'100%',
            boxShadow: '0px 3px 38px #00000017',
            position:'relative',
            height:'100%',

        }}>
            <Box sx={{
                width:'100%',height:'30vh',
                backgroundPosition:' left center',
                backgroundRepeat:' no-repeat',
                backgroundSize:'cover',
                backgroundImage:` url('${prop.image}')`,
            }}>

            </Box>
            {/*<img src={prop.image} style={{width:'100%',height:'30vh',backgroundSize:'auto',backgroundPosition:'center center',*/}
            {/*    backgroundRepeat:' no-repeat',}}/>*/}

            <Box sx={{
                padding:'10px',

            }}>
            <Typography variant={'h5'} fontWeight={'600'} sx={{
                marginTop:'20px'
            }}>
                {prop.title}

            </Typography>
            <Typography sx={{
                color:'#c0c0c0',
                marginTop:'10px'
            }}>
                {/*1 Sep 2023 | 8:35PM*/}
                {prop.time}
            </Typography>

                <Typography  style={{
                    textOverflow:'ellipsis " [..]"',
                    marginTop:'10px',
                    height:'100px',
                    //whiteSpace:'nowrap',
                    overflow: 'hidden',
                }}>
                    {prop.des} ...
                </Typography>
            </Box><Box sx={{
                margin:'20px'
        }}><a href={prop.link} target="_blank">
                <Button sx={{ marginBottom:'15px',marginTop:'30px',background:theme.palette.primary.main,color:theme.palette.customColors.brandColors.tertiary,
                    borderRadius:'0px',
                    padding:'10px 30px !important',
                    "&:hover":{
                        background:'#934d10 !important',}}} color={'primary'} >More</Button>
        </a>
            </Box>
        </Box>
    )
}
export default News