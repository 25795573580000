import * as React from 'react';
import {styled} from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Box, Grid, useMediaQuery} from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Divider from '@mui/material/Divider';


const recipeData = [
    {
        title: "Full Membership",
        price: "1000",
        description:"The membership needs to be renewed annually and the membership fee will be subjected to change at Annual General Meetings.",
        item: [
            "Perpetual access to the alumni platform",
            "Access unique alumni content",
            "Attend exclusive alumni events",
            "Enjoy exclusive discounts and offers",
        ],
        disableItem: [
            "Connect with worldwide Alumni",
            "Career guidance and professional support",
            "Alumni scholarships",
            "Alumni welfare benefits"
        ],
    },
    {
        title: "Life Membership",
        price: "1500",
        discountPrice: 'Rs.2000.00',
        note: 'Register before January 31 and grab the opportunity !',
        description:"The member will be paying a lump sum amount at the point of receiving the membership and the payment is a one time payment and the membership will be renewed annually at no extra cost.",
        item: [
            "Perpetual access to the alumni platform",
            "Access unique alumni content",
            "Attend exclusive alumni events",
            "Enjoy exclusive discounts and offers",
            "Connect with worldwide Alumni",
            "Career guidance and professional support",
            "Alumni scholarships",
            "Alumni welfare benefits"
        ],
        disableItem: [

        ],

    }
];
export default function RecipeCards() {
    return (
        <Grid container spacing={6} sx={{justifyContent:'space-evenly'}}>
            {recipeData.map((recipe, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} >
                    <Card sx={{
                        maxWidth: 360,
                        borderRadius: 4,
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        overflow: 'hidden',
                        border: '2px solid  #c19a6b ',
                    }}>
                        <CardHeader
                            title={
                                <>
                                    <Box sx={{display: 'flex', justifyContent: 'center', my: '5%', color: '#c19a6b'}}>
                                        <strong> {recipe.title}</strong>
                                    </Box>
                                    <hr/>
                                </>
                            }
                        />
                        <CardContent>
                            <Box>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography variant="h4" color="#deb887">
                                        <strong>Rs.{recipe.price}.00</strong>
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography variant="h5" color="#a99a86" sx={{textDecoration: 'line-through'}}>
                                        <strong>{recipe.discountPrice}</strong>
                                    </Typography>
                                </Box>
                                <Box sx={{display:'flex',justifyContent:'center',mb: '2%'}}>
                                    <Typography variant='caption' color='#F27013'
                                                sx={{my: '2%'}}><strong>{recipe.note}</strong></Typography>
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'end',mb:'2%'}}>
                                    <Typography variant="caption" color="#8a795d "> {recipe.description}</Typography>
                                </Box>
                            </Box>
                            {recipe.item.map((point, index) => (
                                <Box key={index}>
                                    <Grid container key={index} alignItems="center">
                                        <Grid item>
                                            <TaskAltIcon sx={{fontSize: '1.5rem'}}/>
                                        </Grid>
                                        <Grid item sx={{ml: '3%'}}>
                                            <Typography variant='caption'>
                                                {point}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}
                            {recipe.disableItem.map((point, index) => (
                                <Box key={index}>
                                    <Grid container key={index} alignItems="center">
                                        <Grid item>
                                            <TaskAltIcon sx={{fontSize: '1.5rem',color:'#acacac'}}/>
                                        </Grid>
                                        <Grid item sx={{ml: '3%'}}>
                                            <Typography variant='caption' sx={{color:'#acacac'}}>
                                                {point}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}


                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}
