import Carousel from "react-material-ui-carousel";
import {Box, Button, Paper, SpeedDialAction, Typography} from "@mui/material";
import slide from '../assets/images/slide1.jpg';
import slide2 from '../assets/images/Alumni/slide2.jpg';
import slide3 from '../assets/images/Alumni/slide3.jpg';
import {theme} from "../stylesheet";
import slide1 from '../assets/images/Alumni/slide1.jpg';
function MainCarousel(){
    return(
        <Carousel autoPlay={true}>

            <Paper>
                <Box sx={{width: '100%', height: '85vh'}}>
                    <Box sx={{position: 'relative'}}>
                        <img src={slide1} className={'Carousel-img'}  style={{backgroundSize:"cover",height:'85vh',width:'100%'}}></img>
                        <Box sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: '1',
                            top: '0',
                            left: '0',
                            background: "linear-gradient(90deg, rgba(3,3,3,1) 0%, rgba(0,0,0,0.49) 63%, rgba(22,20,44,0) 100%)"
                        }}>
                            <Box sx={{
                                top: "50%",
                                left: "10%",
                                zIndex: '2',
                                transform: "translate(0%, -50%)", position: 'absolute',
                                width:{xs:"80%",sm:"80%",md:"80%",lg:"50%",xl:"50%"},
                            }}>
                                <Typography variant={"h1"}
                                            sx={{
                                                color: theme.palette.customColors.brandColors.tertiary,
                                                fontFamily: theme.typography.fontFamily,
                                                fontWeight: '700',
                                                fontSize:{xs:"50px",sm:"50px",md:"50px",lg:"80px",xl:"80px"}

                                            }}>
                                    We Are Proud
                                </Typography>

                                <Typography variant={"h6"}
                                            sx={{
                                                color: theme.palette.customColors.brandColors.tertiary,
                                                fontFamily: 'OpenSans',
                                                marginBottom:'20px',
                                                fontSize:{xs:"18px",sm:"18px",md:"18px",lg:"32px",xl:"32px"}


                                            }}>
                                    Alumni  of<span style={{color: theme.palette.primary.main}}> Uva Wellassa University</span>

                                </Typography>


                                <Typography variant={'formsQuestions'}
                                            sx={{
                                                color: theme.palette.customColors.brandColors.tertiary,
                                                fontFamily: theme.typography.fontFamily,
                                                fontSize: '14px !important'
                                            }}>
                                    We are a vibrant community of former students who share a common educational
                                    background and valuable experiences from Uva Wellassa University. Our dedicated
                                    alumni maintain strong connections with our alma mater, providing essential support,
                                    valuable resources, and active engagement with the university's current students and
                                    programs.

                                </Typography>

                                <Box sx={{display: 'flex', marginTop: '50px'}}>
                                    <Button sx={{
                                        background: theme.palette.primary.main,
                                        padding: '10px 20px !important',
                                        borderRadius: '0px',
                                        fontWeight: '800',
                                        color: theme.palette.customColors.brandColors.tertiary,
                                        "&:hover":{
                                            background:'#934d10 !important',}
                                    }}>Our Mission</Button>
                                    <Button sx={{
                                        background: theme.palette.customColors.brandColors.tertiary,
                                        padding: '10px 20px !important',
                                        borderRadius: '0px',
                                        fontWeight: '500',
                                        marginLeft: '20px',
                                        color: theme.palette.primary.main,
                                        "&:hover":{
                                            background:'#c0c0c0 !important',}
                                    }}>Our Story</Button>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    mt: 5

                                }}>
                                    <Box sx={{
                                        background: theme.palette.primary.main,
                                        width: '80px',
                                        height: '6px',
                                        borderRadius: '6px',
                                        mr: 1
                                    }}></Box>
                                    <Box sx={{
                                        background: theme.palette.customColors.brandColors.tertiary,
                                        width: '6px',
                                        height: '6px',
                                        borderRadius: '50%',
                                        mr: 1
                                    }}></Box>
                                    <Box sx={{
                                        background: theme.palette.customColors.brandColors.tertiary,
                                        width: '6px',
                                        height: '6px',
                                        borderRadius: '50%',
                                        mr: 1
                                    }}></Box>

                                </Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper>
            <Paper>
                <Box sx={{width:'100%',height:'85vh'}}>
                    <img src={slide2} className={'Carousel-img'}  style={{backgroundSize:"cover",height:'85vh',width:'100%'}}></img>
                    <Box sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: '1',
                        top: '0',
                        left: '0',
                        background: "linear-gradient(90deg, rgba(3,3,3,1) 0%, rgba(0,0,0,0.49) 63%, rgba(22,20,44,0) 100%)"
                    }}>
                        <Box sx={{
                            top:"50%",
                            left: "10%",
                            zIndex: '2',
                            width:{xs:"80%",sm:"80%",md:"80%",lg:"50%",xl:"50%"},
                            transform: "translate(0%, -50%)", position: 'absolute'
                        }}>
                            <Typography variant={"h1"}
                                        sx={{
                                            color: theme.palette.customColors.brandColors.tertiary,
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: '700',
                                            fontSize:{xs:"50px",sm:"50px",md:"50px",lg:"80px",xl:"80px"}

                                        }}>
                                We Are Proud
                            </Typography>

                            <Typography variant={"h6"}
                                        sx={{
                                            color: theme.palette.customColors.brandColors.tertiary,
                                            fontFamily: 'OpenSans',
                                            marginBottom:'20px',
                                            fontSize:{xs:"18px",sm:"18px",md:"18px",lg:"32px",xl:"32px"}


                                        }}>
                                Alumni  of<span style={{color: theme.palette.primary.main}}> Uva Wellassa University</span>

                            </Typography>
                            <Typography variant={'formsQuestions'}
                                        sx={{
                                            color: theme.palette.customColors.brandColors.tertiary,
                                            fontFamily: theme.typography.fontFamily,
                                            fontSize:'14px !important'
                                        }}> We are a vibrant community of former students who share a common educational
                                background and valuable experiences from Uva Wellassa University. Our dedicated
                                alumni maintain strong connections with our alma mater, providing essential support,
                                valuable resources, and active engagement with the university's current students and
                                programs.
                            </Typography>

                            <Box sx={{display: 'flex', marginTop: '50px'}}>
                                <Button sx={{
                                    background: theme.palette.primary.main,
                                    padding: '10px 20px !important',
                                    borderRadius: '0px',
                                    fontWeight: '800',
                                    color: theme.palette.customColors.brandColors.tertiary,
                                    "&:hover":{
                                        background:'#934d10 !important',}
                                }}>Our Mission</Button>
                                <Button sx={{
                                    background: theme.palette.customColors.brandColors.tertiary,
                                    padding: '10px 20px !important',
                                    borderRadius: '0px',
                                    fontWeight: '500',
                                    marginLeft: '20px',
                                    color: theme.palette.primary.main,
                                    "&:hover":{
                                        background:'#c0c0c0 !important',}
                                }}>Our Story</Button>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                mt: 5

                            }}>

                                <Box sx={{
                                    background: theme.palette.customColors.brandColors.tertiary,
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '50%',
                                    mr: 1
                                }}></Box>
                                <Box sx={{
                                    background: theme.palette.primary.main,
                                    width: '80px',
                                    height: '6px',
                                    borderRadius: '6px',
                                    mr: 1
                                }}></Box>

                                <Box sx={{
                                    background: theme.palette.customColors.brandColors.tertiary,
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '50%',
                                    mr: 1
                                }}></Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper>
            <Paper>
                <Box sx={{width:'100%',height:'85vh'}}>
                    <img src={slide3} className={'Carousel-img'}  style={{backgroundSize:"cover",height:'85vh',width:'100%'}}></img>
                    <Box sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: '1',
                        top: '0',
                        left: '0',
                        background: "linear-gradient(90deg, rgba(3,3,3,1) 0%, rgba(0,0,0,0.49) 63%, rgba(22,20,44,0) 100%)"
                    }}>
                        <Box sx={{
                            top: "50%",
                            left: "10%",
                            width:{xs:"80%",sm:"80%",md:"80%",lg:"50%",xl:"50%"},
                            zIndex: '2',
                            transform: "translate(0%, -50%)", position: 'absolute'
                        }}>
                            <Typography variant={"h1"}
                                        sx={{
                                            color: theme.palette.customColors.brandColors.tertiary,
                                            fontFamily: theme.typography.fontFamily,
                                            fontWeight: '700',
                                            fontSize:{xs:"50px",sm:"50px",md:"50px",lg:"80px",xl:"80px"}

                                        }}>
                                We Are Proud
                            </Typography>

                            <Typography variant={"h6"}
                                        sx={{
                                            color: theme.palette.customColors.brandColors.tertiary,
                                            fontFamily: 'OpenSans',
                                            marginBottom:'20px',
                                            fontSize:{xs:"18px",sm:"18px",md:"18px",lg:"32px",xl:"32px"}


                                        }}>
                                Alumni  of<span style={{color: theme.palette.primary.main}}> Uva Wellassa University</span>

                            </Typography>
                            <Typography variant={'formsQuestions'}
                                        sx={{
                                            color: theme.palette.customColors.brandColors.tertiary,
                                            fontFamily: theme.typography.fontFamily,
                                            fontSize:'14px !important'
                                        }}> We are a vibrant community of former students who share a common educational
                                background and valuable experiences from Uva Wellassa University. Our dedicated
                                alumni maintain strong connections with our alma mater, providing essential support,
                                valuable resources, and active engagement with the university's current students and
                                programs.
                            </Typography>
                            <Box sx={{display: 'flex', marginTop: '50px'}}>
                                <Button sx={{
                                    background: theme.palette.primary.main,
                                    padding: '10px 20px !important',
                                    borderRadius: '0px',
                                    fontWeight: '800',
                                    color: theme.palette.customColors.brandColors.tertiary,
                                    "&:hover":{
                                        background:'#934d10 !important',}
                                }}>Our Mission</Button>
                                <Button sx={{
                                    background: theme.palette.customColors.brandColors.tertiary,
                                    padding: '10px 20px !important',
                                    borderRadius: '0px',
                                    fontWeight: '500',
                                    marginLeft: '20px',
                                    color: theme.palette.primary.main,
                                    "&:hover":{
                                        background:'#c0c0c0 !important',}
                                }}>Our Story</Button>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                mt: 5

                            }}>

                                <Box sx={{
                                    background: theme.palette.customColors.brandColors.tertiary,
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '50%',
                                    mr: 1
                                }}></Box>
                                <Box sx={{
                                    background: theme.palette.customColors.brandColors.tertiary,
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '50%',
                                    mr: 1
                                }}></Box>
                                <Box sx={{
                                    background: theme.palette.primary.main,
                                    width: '80px',
                                    height: '6px',
                                    borderRadius: '6px',
                                    mr: 1
                                }}></Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Carousel>
    );

}
export default  MainCarousel