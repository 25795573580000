import {Theme} from '@mui/material'

const Typography = (theme: Theme) => {
    return {
        MuiTypography: {
            styleOverrides: {
                //fontFamily: ['Roboto'].join(','),
                fontFamily:'Roboto'
            },
        },
    }
}

export default Typography
