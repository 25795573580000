import {Box, Typography} from "@mui/material";
import aboutimg from '../assets/images/Alumni/aboutimg.jpg';

function About() {
    return (<Box id={'About'}
            sx={{
                background: '#EFF1F4',
                marginTop: '50px',
                padding: '50px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
            <Typography variant={'h2'} sx={{color: '#091D3D'}} fontWeight={'700'} align={'center'}>
                About Alumni
            </Typography>
            <Box sx={{
                width: '90%',
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <Typography variant={'h1'} fontWeight={'800'} sx={{
                    color: 'white',

                }}>
                    2023
                </Typography>

            </Box>
<Box sx={{
    width:'90%',
    display:'flex',
    justifyContent:'right'
}}>
            <Box sx={{
                background: 'white',
                marginTop: '0px'
                , width: '89%',
                padding: '20px'
            }}>
                {/*-95px 36px 23px 55px*/}
                <Typography align={'justify'}>
                    <img src={aboutimg} style={{
                        float: 'left',
                        margin:'-69px 28px 23px -80px'
                    }} width={'50%'}/>
                    <p>
                        <Typography variant={'h5'} fontWeight={'700'} sx={{
                        padding: '20px'
                    }} align={'center'}>UWU Alumni Association
                    </Typography>
                        <b>“Although proudly rooted in Sri Lanka, our community and impact are global”</b>
                        <p>The Uva Wellassa University of Sri Lanka, is 14th the National University of Sri Lanka, Offering 13 Undergraduate programmes to around 2000 students in four academic years.. Although proudly rooted in Badulla, Sri Lanka, our community and impact are global.</p>
                      <p>  We, being the national leader in Value Addition to the new learning and teaching skills to the Undergraduates and Postgraduates students and we promote their value to society and natural resources, and the economy through knowledge creation and exchange.</p>
                        <p>Our passion for increasing access to the Skilled and educated expertise to the national economy and transform the lives of Sri Lankan graduates to address the global challenges of the future.</p>
                    </p>

                </Typography>
            </Box>
</Box>


        </Box>
    )
}

export default About