import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import breakpoints from './@core/theme/breakpoints/index'
import overrides from './@core/theme/overrides/index'
import palette from './@core/theme/palette/index'
import typography from './@core/theme/typography/index'

// ** Merged ThemeOptions of Core
export let theme = createTheme({
  palette: { ...palette() },
  // typography: {
  //     fontFamily: ['Muli', 'serif', 'Roboto'].join(','),
  // },
  breakpoints: breakpoints(),
})

// ** Continue theme creation and pass merged component overrides to CreateTheme function
theme = createTheme(theme, {
  components: { ...overrides(theme) },
  // typography: { ...typography(theme) },
})
theme = responsiveFontSizes(theme);