import {Box, Grid, Typography} from "@mui/material";
import scholarshipimg from "../assets/icons/scholarship.png";
import help from "../assets/icons/help.png";
import university from "../assets/icons/university.png";
import community from "../assets/icons/community.png";
function ResponsibilityBar(){
    return(
        <Box sx={{
            width:'100%',
            display:'flex',
            justifyContent:'center',
        }}>
            <Box sx={{width:'80%'}}>
            <Typography align={'center'} variant={"h3"} sx={{
                padding:'50px',
                fontWeight:'600',
                color:'#091D3D'
            }}>Our Responsibility</Typography>

            <Grid container spacing={4}>
                <Grid item md={3} sx={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center'
                }}>
                    <img src={scholarshipimg} style={{
                        width:'50%',
                        margin:'20px'


                    }}/>
                    <Typography align={'center'} fontWeight={'700'} variant={'h6'}>
                        Scholarship
                    </Typography>
                    <Typography sx={{margin:'20px'}} align={'center'}>Alumni Association of Uva Wellassa Univeristy as a part of its social responsibility, is initiating scholarship programme for undergraduate students of UWU by eliminating or reducing financial barriers to ensure academic success.  </Typography>
                </Grid>


                <Grid item md={3} sx={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center'
                }}>
                    <img src={help} style={{
                        width:'50%',
                        margin:'20px'


                    }}/>
                    <Typography align={'center'} fontWeight={'700'} variant={'h6'}>
                        Help Current Students
                    </Typography>
                    <Typography sx={{margin:'20px'}} align={'center'}>As a UWU graduate you can access a Alumni Association for business services, mentoring, career guidance, continuing professional development and state-of-the-art technical facilities to consultancy, technology, value addition and research and development. .</Typography>
                </Grid>




                <Grid item md={3} sx={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center'
                }}>
                    <img src={university} style={{
                        width:'50%',
                        margin:'20px'


                    }}/>
                    <Typography align={'center'} fontWeight={'700'} variant={'h6'}>
                        Help Our University
                    </Typography>
                    <Typography sx={{margin:'20px'}} align={'center'}>We expected to develop our strategy to develop and assist the academic policies and regulations of the UWU. This strategy aims to support high quality learning and maintenance of academic standards that underpins our Research and Academic excellence to compete with the global scenarios</Typography>
                </Grid>



                <Grid item md={3} sx={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center'
                }}>
                    <img src={community} style={{
                        width:'50%',
                        margin:'20px'


                    }}/>
                    <Typography align={'center'} fontWeight={'700'} variant={'h6'}>
                        Build Our Community
                    </Typography>
                    <Typography align={'center'} sx={{margin:'20px'}}>Connecting with Alumni provides valuable opportunity to continue learning and building relationships beyond UWU. It builds relationship between Alumni Association of UWU and the Faculty Alumni Associations. Become a member and stay connected with your fellows.  </Typography>
                </Grid>



            </Grid>
            </Box>

        </Box>
    )
}
export  default  ResponsibilityBar