import {Box, Grid, Typography} from "@mui/material";
import img from '../assets/images/Alumni/presidentimg.jpg';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import {theme} from "../stylesheet";
function PresidentMessage(){
    return(
        <Box
        sx={{
            display:'flex',
            justifyContent:'center',

            flexDirection:'column',
            alignItems:'center'
        }}>
            <Box sx={{
                width:'85%'
            }}>
                <Typography align={'left'} width={'100%'} variant={'h3'} fontWeight={'600'}
                sx={{color:'#091D3D'}}>President’s Message
                </Typography>


            </Box>

            <Box sx={{
                width:'100%',
                marginTop:'50px'
            }}>
                <Grid container spacing={0}>
                    <Grid item md={4}>
                        <img src={img} style={{
                            height:'100%',
                            width:'100%'
                        }}/>
                    </Grid>

                    <Grid item md={8} sx={{
                        background:'#ECECEC',
                        display:'flex',
                        //justifyContent:'center',
                        alignItems:'center',
                        flexDirection:'column'
                        ,padding:'50px'

                    }}>

                        <Typography align={'center'} width={'100%'}  fontWeight={'700'} sx={{color:' #343741',fontSize:'35px'}}>
                        Mithila Karunarathne 

                        </Typography>
                        <Typography align={'center'} width={'100%'} variant={'h6'} fontWeight={'100'} sx={{color:' #343741',p:1,opacity:'0.8'}}>
                        {/* (Working Committee President) */}
                        </Typography>
                        <Box sx={{
                            color:theme.palette.customColors.brandColors.tertiary,
                            background:theme.palette.primary.main,
                            borderRadius:'50%',
                            padding:'10px',
                            width:'30px',
                            height:'30px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            margin:'10px'
                        }}>
                            <FormatQuoteIcon fontWeight={800}/>
                        </Box>

                        <Typography align={'center'} width={'80%'} variant={'h6'} fontWeight={'100'}
                                    sx={{marginTop: '20px',color: '#343741',
                                    fontSize:'18px'}}>Alumni Association of Uva
                            Wellassa University aims to foster connections, facilitate networking, and promote lifelong
                            learning among Uva Wellassa University graduates. Through mentorship and collaboration, we
                            aspire to create a strong community that celebrates shared achievements and contributes to
                            the continuous success of our esteemed alma mater."All Uva Wellassa University alumni are
                            warmly invited to join this enduring journey.

                        </Typography>


                    </Grid>

                </Grid>
            </Box>

        </Box>
    )
}
export default PresidentMessage