import React from "react";
import "./App.css";
import AppBarh from "./components/AppBarh";
import MainCarousel from "./components/MainCarousel";
import EventBar from "./components/EventBar";
import About from "./components/About";
import ResponsibilityBar from "./components/ResponsibilityBar";
import NewsBar from "./components/NewsBar";
import PresidentMessage from "./components/PresidentMessage";
import Footer from "./components/Footer";

function App() {

    return (
      <div>
        <AppBarh />
        <MainCarousel />
        <EventBar />
        <About />
        <ResponsibilityBar />
        <NewsBar />
        <PresidentMessage />
        <Footer />
      </div>
    );
}

export default App;
