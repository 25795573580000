
import AppBarh from "../components/AppBarh";
import Footer from "../components/Footer";

export default function Constitution() {
    const pdfFilePath = 'https://uwualumni.com/constitution.pdf'; // Relative path from the public folder
    const iframestyle = {

    }
    return (
        <div className="Example">
            <AppBarh/>
            <iframe style={{
                width:'100%',
                height:'100vh',
            }} src='https://uwualumni.com/constitution.pdf' width="" height="" />

            <Footer/>
        </div>
    );
}