import AppBarh from "../components/AppBarh";
import Footer from "../components/Footer";
import MembershipView from "../components/MembershipView";
import {Box, Button, Grid, Typography} from "@mui/material";
import tokenid from "../assets/images/Token.png";

export default function MemberRegistration() {
    const pdfFilePath = 'https://uwualumni.com/constitution.pdf'; // Relative path from the public folder
    const iframestyle = {};
    const redirectToRegistration = () => {
        window.location.href = 'https://app.rewavo.com/alumni-registration';
    };
    return (
        <div className="Example">
            <AppBarh/>
            <Grid item xs={12} sx={{mx: '4%', mt: '2%'}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant='h4'>Member Registration</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', justifyContent: 'center', mx: '3%'}}>
                            <Button
                                color={'primary'}
                                variant='contained'
                                size='large'
                                onClick={redirectToRegistration}
                                sx={{textAlign: 'center', textTransform: 'capitalize'}}
                            >
                                <Typography variant='subtitle1' sx={{color: 'white', fontWeight: 'bold'}}>Register
                                    Now!</Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{mx: '1%', mb: '3%'}}>
                <Box sx={{ml: '4%'}}>
                    <Box sx={{mt: '3%', mb: '1%', mx: '4%', display: 'flex', justifyContent: 'center'}}>
                        <Typography variant='h6' sx={{my: '1%'}}> Great Opportunity to Join the Prestigious UWU Alumni
                            Community!</Typography>
                    </Box>
                    <Box>
                        <Typography variant='h6'>Registration Instruction</Typography>

                        <Box>
                            <Typography variant='subtitle1' sx={{mt: '1%', ml: '4%'}}>
                                <li><strong>Personal Information:</strong></li>
                                <Typography variant='body1' sx={{ml: '2%'}}>
                                    Please provide accurate and up-to-date personal details, including your full name,
                                    contact information, and any other relevant information.
                                </Typography>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant='subtitle1' sx={{mt: '1%', ml: '4%'}}>
                                <li><strong>Professional Information:</strong></li>
                                <Typography variant='body1' sx={{ml: '2%'}}>Fill in your professional details such as
                                    Complete the section with your current occupation, designation, and other pertinent
                                    professional details.</Typography>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant='subtitle1' sx={{mt: '1%', ml: '4%'}}>
                                <li><strong>Required Documents:</strong></li>


                                <Typography variant='body1' sx={{mt: '1%', ml: '2%'}}>1.Profile Picture:</Typography>
                                <Typography variant='body1' sx={{ml: '4%'}}>Upload a clear and recent profile picture in
                                    formats<strong><span
                                        style={{color: '#555555'}}>(.jpeg, .png, .jpg)</span></strong> This will
                                    contribute to creating a vibrant and recognizable alumni community.</Typography>


                                <Typography variant='body1' sx={{mt: '1%', ml: '2%'}}>2.Degree Certificate:</Typography>
                                <Typography sx={{mt: '1%', ml: '4%'}}>Attach a scanned or clear image in
                                    formats <strong><span
                                        style={{color: '#555555'}}>(.jpeg, .png, .jpg)</span></strong> or PDF
                                    file<strong><span style={{color: '#555555'}}>(.pdf)</span></strong> of your degree
                                    certificate/academic transcription.
                                    Crucial for verifying your alumni status.</Typography>


                                <Typography variant='body1' sx={{mt: '1%', ml: '2%'}}>3.Payment
                                    Confirmation:</Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mx: '10%',
                                        mt: '5%',
                                        mb: '2%',

                                    }}
                                >
                                    <MembershipView/>
                                </Box>

                                <Typography variant='body1' sx={{mt: '1%', ml: '4%'}}>3.a.Bank Payment
                                    Slip:</Typography>
                                <Typography sx={{mt: '1%', ml: '6%'}}>Ensure payment completion before finalizing the
                                    registration process.</Typography>
                                <Typography sx={{ml: '6%', '& ul': {paddingInlineStart: 2, listStyleType: 'none'}}}>
                                    <ul>

                                    <span style={{color: '#e77817'}}>
                                        <li>Bank: <strong>Commercial Bank </strong></li>
                                        <li>Bank Code: <strong>7056</strong></li>
                                        <li>Branch:<strong> Rajagiriya Branch</strong></li>
                                        <li>Branch Code:<strong> 62</strong></li>
                                        <li>Account Name:<strong> Alumni Association of Uva Wellassa University</strong></li>
                                        <li>Account Number: <strong>8019717186</strong></li>
                                        <li>Swift Code:<strong> CCEYLKLX</strong></li>
                                    </span>
                                    </ul>
                                </Typography>
                                <Typography variant='body1' sx={{ml: '6%'}}>Upload a picture in formats <strong><span
                                    style={{color: '#555555'}}>(.jpeg, .png, .jpg)</span></strong> or PDF
                                    file<strong><span style={{color: '#555555'}}>(.pdf)</span></strong> of the bank
                                    payment slip post-payment to confirm your registration.</Typography>






                                <Typography variant='body1' sx={{mt: '1%', ml: '4%'}}>3.b.Cash Token Details:
                                </Typography>
                                <Typography variant='body1' sx={{mt: '1%', ml: '6%'}}> For cash payments, purchase a valid token card from the Alumni Association of Uva Wellassa University. Tokens are available at UWU events.</Typography>
                                <br/>
                                    <Typography variant='body1' sx={{ml: '6%'}}>Upload a picture in formats<strong><span
                                        style={{color: '#555555'}}>(.jpeg, .png, .jpg)</span></strong> or PDF
                                    file<strong><span style={{color: '#555555'}}>(.pdf)</span></strong> of the cash token for verification.</Typography>
                                <Box width='100%' sx={{display: 'flex', justifyContent: 'center', my: '2%'}}>
                                    <img src={tokenid} alt=""/>
                                </Box>
                            </Typography>
                        </Box>

                        <Typography sx={{color: '#F27013', my: '1%'}}>Before initiating registration, ensure you have the necessary documents ready: <strong>Academic
                                Transcripts</strong> or <strong>Degree Certificates</strong>, and <strong>Bank
                                Receipts</strong> or <strong>Cash Token</strong>.</Typography>
                        <Box>
                            <Typography variant='subtitle1' sx={{mt: '2%', color: '#F27013'}}><strong>Please note
                                :</strong></Typography>
                            <Typography variant='body1' sx={{mt: '1%', ml: '2%', color: '#F27013'}}>
                                <li>Verify the accuracy of all the provided informations.</li>
                                <li>If paying by cash token, purchase a valid token card from UWU Alumni.
                                </li>
                                <li>Double-check bank details or cash token details before making payment.</li>

                            </Typography>

                        </Box>
                    </Box>
                </Box>
                <Box sx={{my: '2%'}}>
                    <hr/>
                </Box>

                <Box sx={{ml: '6%', my: '2%'}}>
                    <Typography variant={'h6'}>Why do we need an Alumni association?</Typography>
                    <Box sx={{ml: '2%'}}>
                        <Box sx={{mt: '1%'}}>
                            <li>Cohesion: Unite with esteemed graduates, enhancing UWU's reputation.
                            </li>
                            <Typography sx={{ml: '2%'}}>Join forces with distinguished alumni to bolster the esteemed
                                standing of
                                UWU.
                            </Typography>
                        </Box>
                        <Box sx={{mt: '1%'}}>
                            <li>Information Hub: Get the scoop on university updates and community
                                news.
                            </li>
                            <Typography sx={{ml: '2%'}}>Discover the latest information and stay up-to-date with the
                                latest
                                news from the university and the local and international community.
                            </Typography>
                        </Box>
                        <Box sx={{mt: '1%'}}>
                            <li>Empowering Alumni: Access programs for your personal and professional
                                development.
                            </li>
                            <Typography sx={{ml: '2%'}}>Explore a multitude of programs tailored to enhance your
                                personal
                                and professional growth.
                            </Typography>
                        </Box>
                        <Box sx={{mt: '1%'}}>
                            <li>Academic Excellence: Power the ongoing journey towards academic
                                excellence.
                            </li>
                            <Typography sx={{ml: '2%'}}>Empower the continuous voyage towards achieving scholastic
                                brilliance.
                            </Typography>
                        </Box>
                        <Box sx={{mt: '1%'}}>
                            <li>Crafting Futures: Enhance undergraduates' skills for a smooth transition to
                                professional life.
                            </li>
                            <Typography sx={{ml: '2%'}}>Improve the skills of undergraduate students to facilitate a
                                seamless
                                transition into their professional careers.
                            </Typography>
                        </Box>
                    </Box>

                    <Typography variant={'h6'} sx={{mt: '2%'}}>Why register with UWU
                        Alumni?</Typography>
                    <Box sx={{ml: '2%'}}>
                        <Box sx={{mt: '1%'}}>
                            <li>Networking: Forge professional connection and mentorship opportunities.
                            </li>
                            <Typography sx={{ml: '2%'}}>Develop meaningful professional relationships and create
                                opportunities for
                                mentorship.
                            </Typography>
                        </Box>
                        <Box sx={{mt: '1%'}}>
                            <li>Stay Informed: Stay tuned to UWU's achievements, news, and exciting
                                programs.
                            </li>
                            <Typography sx={{ml: '2%'}}>Keep yourself updated on UWU's remarkable accomplishments,
                                latest
                                updates, and thrilling initiatives.
                            </Typography>
                        </Box>
                        <Box sx={{mt: '1%'}}>
                            <li>Recognition: Formalize ties with UWU Alumni for a deeper sense of
                                belonging.
                            </li>
                            <Typography sx={{ml: '2%'}}>Strengthen the bonds with UWU Alumni to foster a more profound
                                feeling of
                                connection and affiliation.
                            </Typography>
                        </Box>
                        <Box sx={{mt: '1%'}}>
                            <li>Unlock Benefits: Enjoy exclusive perks – job services, education discounts, and
                                event
                                invites!
                            </li>
                            <Typography sx={{ml: '2%'}}>Experience a wide array of exclusive benefits - unparalleled
                                services,
                                substantial education discounts, and exclusive invitations to extraordinary
                                events.
                            </Typography>
                        </Box>
                    </Box>
                    <Typography sx={{mt: '1%'}}>
                        Seize the opportunity to register today and indulge in unparalleled benefits tailored
                        exclusively for you—the alumni of Uva Wellassa University.
                    </Typography>
                </Box>
                <Typography sx={{mt: '2%', ml: '2%'}}>
                    Thank you for joining the UWU Alumni Association! We look forward to staying connected and
                    sharing the latest updates and events with you.Contact us at <a
                    href={'mailto:alumni@std.uwu.ac.lk'}> alumni@std.uwu.ac.lk</a> for any registration-related queries
                    or concerns.
                </Typography>
                <Box sx={{display: 'flex', justifyContent: 'center', m: '3%'}}>
                    <Button
                        color={'primary'}
                        variant='contained'
                        size='large'
                        onClick={redirectToRegistration}
                        sx={{textAlign: 'center', textTransform: 'capitalize'}}
                    >
                        <Typography variant='subtitle1' sx={{color: 'white', fontWeight: 'bold'}}>Register
                            Now!</Typography>
                    </Button>
                </Box>
            </Box>
            <Footer/>
        </div>
    );
}
