import { useEffect, useState } from "react";

type CountdownReturnType = [number, number, number, number]; // Define the return type

const useCountdown = (targetDate: string): CountdownReturnType => { // Add type annotations
    const countDownDate: number = new Date(targetDate).getTime();

   // const [countDown, setCountDown] = useState<number>(countDownDate - new Date().getTime());
    const [countDown, setCountDown] = useState<number>(0);
    useEffect(() => {
        const interval = setInterval(() => {
            const newCountDown = countDownDate - new Date().getTime();
            if (newCountDown <= 0) {
                setCountDown(0);
                clearInterval(interval); // Clear the interval when countdown reaches zero
            } else {
                setCountDown(newCountDown);
            }
        }, 1000);

       return () => clearInterval(interval);


    }, [countDownDate]);


    return getReturnValues(countDown) as CountdownReturnType; // Cast return value to the defined type
};

const getReturnValues = (countDown: number): CountdownReturnType => {
    const days: number = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours: number = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes: number = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds: number = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

export { useCountdown };
